.filter-item {
  &:not(.ebs-button__group) {
    min-width: rem(200px);
    max-width: rem(300px);
  }

  .ebs-sort-by__wrapper .ebs-button--ghost .ebs-button {
    @include fontMedium();

    &:hover svg.ebs-icon {
      color: $white !important;
    }

    .ebs-icon {
      color: $headers-color;
    }
  }
  .ebs-select__prefix {
    svg.ebs-icon {
      color: #18244c !important;
    }
  }

  .ebs-select-value .ebs-select-value__container {
    color: #18244c !important;
  }

  &.ebs-datepicker {
    color: #18244c !important;

    &::placeholder {
      color: #18244c !important;
    }
  }

  .ebs-input__container .ebs-input {
    color: #18244c !important;

    &::placeholder {
      color: #18244c !important;
    }
  }
}

.ebs-sort-by__tooltip-items .ebs-sort-by__tooltip-item:hover {
  color: $primary-color;
}
