.visit-form {
  &__landline-number.ebs-space {
    .ebs-space__item:first-child {
      width: 100%;
    }
  }

  &__label.ebs-label {
    margin-bottom: rem(4px);
    font-weight: $font-weight-base;
  }
  &__add-lead {
    width: rem(125px);
  }
}
