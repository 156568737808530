.check-duplicates {
  .wrap-tables {
    max-width: rem(590px);

    .ebs-table {
      th {
        padding: rem(15px 25px);
      }

      td {
        padding: 0;
        line-height: rem(18px);
        cursor: pointer;

        & > div {
          padding: rem(10px 25px);
        }

        svg {
          margin-top: rem(3px);
          font-size: rem(12px);
          float: right;

          &.ebs-icon--arrow-left {
            float: left;
            margin-right: rem(10px);
          }
        }
      }
    }
    .ebs-alert--warning {
      border: 1px dotted $warning-color;
      background-color: $warning-background-color;
    }
    .ebs-alert--error {
      border: 1px dotted $danger-color;
      background-color: rgba(255, 197, 172, 0.3);
    }
  }
}
