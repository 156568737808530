.ebs-sidebar {
  &__item {
    .ebs-icon--user-profile {
      font-size: rem(32px);
      margin-left: rem(-8px);
    }

    &:hover,
    &.active-background {
      background-color: $primary-color;
      color: $white;

      & + .ebs-sidebar__suffix svg {
        color: $white;
      }

      a,
      svg {
        color: $white;
      }
    }

    &.active {
      background-color: $primary-100;
    }
  }

  &__item.active-background &__text {
    color: $white;
  }

  &__top .ebs-tooltip__trigger {
    margin: unset;
    padding: unset;
  }

  .user-profile {
    svg {
      width: rem(32px);
      height: rem(32px);
      margin-left: rem(-5px);
    }
  }

  @include mq($until: small-desktop) {
    &.ebs-sidebar__mobile--opened {
      z-index: 999;
    }
  }
}
