.import-info {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: rem(1px solid $border-color);
  border-radius: rem(3px);
  text-align: center;
  line-height: rem(28px);
  margin: rem(0 0 20px 0);

  &__item {
    padding: rem(8px 20px);
    border-right: rem(1px solid $border-color);
    min-height: rem(45px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:last-child {
      border: none;
    }
  }

  &__key {
    flex: 0 0 auto;
  }

  &__name {
    flex: 1 1 50%;
    min-width: rem(150px);
  }

  &__mail {
    flex: 1 1 50%;
    min-width: rem(150px);
  }

  &__status {
    flex: 1 1 20%;
    min-width: rem(150px);

    .no-wrap {
      width: rem(120px);
    }
  }

  &__progress {
    flex: 1 1 100%;
    padding-right: 0;
  }

  .progress-bar {
    display: flex;
    align-items: center;
    height: unset;
    width: unset;
    border-radius: unset;
    background-color: unset;

    .progress-bar_progress {
      background-color: #d7dae0;
      height: rem(28px);
      width: 100%;
      border-radius: rem(3px);
      overflow: hidden;

      .progress-bar_progress-line {
        background-color: #2aa94e;
        height: rem(28px);
      }
    }

    .progress-bar_percent {
      width: rem(80px);
      text-align: center;
      font-size: rem(12px);
    }
  }
}
