@import '~@ebs-platform/connect/dist/styles/index.scss';
@import './palette', './variables', './typography';

@import '~ebs-design/dist/styles/index';
@import '~ebs-design/dist/styles/default';
@import '~ebs-design/dist/styles/scrollbar';

@import 'react-h5-audio-player/lib/styles';
@import 'rc-slider/assets';

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p,
span {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

h3 {
  font-size: rem(16px);
  color: $headers-color;
  font-family: $font-family;
  font-weight: 500;

  &.title {
    font-weight: 700;
  }
}

hr {
  background-color: $border-color;
  height: rem(1px);
  border: none;
  margin: rem(32px 0);
}

a {
  color: $ahref-color;
  transition: 0.2s;

  &:hover {
    color: $ahref-hover-color;
  }
}

.ebs-layout__footer {
  color: #a5a5a5;
  font-size: rem(11px);
  @include fontMedium;

  span b {
    color: $green;
  }
}

// Card title styles, usually used with h4
.card-title {
  font-weight: 600;
  font-size: rem(14px);
  margin: 0;
}

.root-spinner {
  margin: auto;
  width: rem(100px);
  margin-top: rem(50px);

  .ebs-loader__spinner--regular {
    width: rem(100px);
    height: rem(100px);
  }
}

.ebs-radio__group {
  .ebs-radio {
    width: rem(18px);
    height: rem(18px);

    .ebs-radio__dot {
      width: rem(13px);
      height: rem(13px);
    }
  }

  .ebs-radio__text {
    font-size: rem(9.75px);
  }

  .ebs-radio__input {
    &:checked {
      & ~ .ebs-radio {
        .ebs-radio__dot {
          border-color: $sidebar-item-hover-background;
          background-color: #fff;
          box-shadow: rem(0px 0px 0px 2.5px $sidebar-item-hover-background inset);
        }
      }
    }
  }

  .ebs-radio__text {
    line-height: rem(15px);
  }
}

.ebs-icon--dots {
  font-size: rem(12px);
}

.ebs-checkbox__input {
  &:checked {
    & ~ .ebs-checkbox {
      background-color: $sidebar-item-hover-background;
      border-color: $sidebar-item-hover-background;
    }
  }
}
.ebs-button--prefix {
  .ebs-button__prefix {
    .ebs-loader__spinner.ebs-loader__spinner--small {
      border: 2px solid $primary-700;
      border-top: 2px solid $white;
    }
  }
}
.text {
  &-right {
    text-align: right;
  }
}

.m {
  &r {
    &-10 {
      margin-right: rem(10px);
    }
  }

  &b {
    &-10 {
      margin-bottom: rem(10px);
    }

    &-20 {
      margin-bottom: rem(20px);
    }
  }
}

.ebs-checkbox {
  border-radius: rem(2px);

  .ebs-card {
    box-shadow: none;
  }

  .mr {
    &-10 {
      margin-right: rem(10px);
    }
  }

  .form-wrapper {
    background-color: $warning-500;
    border-radius: rem(3px 3px);
    border: rem(1px solid $border-color);
    padding: rem(25px 30px);
    overflow: hidden;

    .ebs-form {
      max-width: rem(800px);
    }

    .form-footer {
      background-color: #fff;
      padding: rem(15px);
      margin: rem(30px -30px -25px -30px);
      border-top: rem(1px solid $border-color);

      .ebs-button__wrapper {
        &:first-child {
          margin-right: rem(20px);
        }
      }
    }
  }

  .ebs-button--primary {
    background-color: $green;
    border-color: $green;
  }

  .table-wrapper {
    .ebs-table {
      .rc-table-content {
        border-bottom: none;
        border: none;
      }
    }

    .table-footer {
      border: rem(1px solid $border-color);
      border-top: none;
      margin: 0;
      padding: rem(15px 10px);
      border-radius: rem(0 0 5px 5px);
      color: #262626;
      font-size: rem(11px);

      .count {
        padding-top: rem(6px);
      }

      .ebs-button {
        color: $primary-color;
        font-weight: 500;

        &:disabled {
          background-color: #f1f2f5;
          color: #768093;
        }
      }
    }
  }

  .ebs-card {
    box-shadow: none;

    .ebs-table {
      .rc-table-content {
        border: none;
      }
    }
  }

  .label-form {
    margin: rem(20px 0 15px 0);
    font-size: rem(14px);
    font-weight: 500;
  }
}

.equal-height-columns {
  .ebs-card {
    display: flex;
    flex-direction: column;

    &__body {
      height: 100%;
    }
  }

  .fade-in,
  .ebs-loader,
  .ebs-card,
  .ebs-table,
  .rc-table-container,
  .rc-table-content,
  table {
    height: 100%;
  }
}

.pointer {
  cursor: pointer;
}

.bg-selected {
  background: $border-color;
}

.no-avatar {
  .ebs-avatar {
    display: none;

    & + div {
      margin-left: 0;
    }
  }
}
