.action-card {
  position: relative;

  &.ebs-card--small {
    .action-card__header.ebs-card__header {
      padding: rem(8px 8px 8px 40px);
    }
  }

  &__count {
    position: absolute;
    background-color: $primary-color;
    width: rem(31px);
    height: rem(34px);
    font-size: rem(13px);
    line-height: rem(34px);
    text-align: center;
    color: white;
    top: 0;
    left: 0;
  }

  &__add-new {
    width: rem(34px);
    height: rem(34px);
    margin: auto;
    position: absolute;
    border-left: 1px solid $border-color;
    right: 0;
    top: 0;
    cursor: pointer;

    svg {
      display: block;
      width: rem(14px);
      height: rem(14px);
      margin: rem(10px);
      color: $ahref-color;
    }
  }

  &__save-button {
    width: rem(13px);
    height: rem(10px);
  }

  &__body.ebs-card__body {
    background-color: $white;
  }
}
