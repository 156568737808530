.text-right {
  .arrow-position {
    position: relative;
    top: 2px;
  }
  .arrow-left {
    margin-right: 10px;
  }
  .arrow-right {
    margin-left: 10px;
  }
}
