.balls-palette {
  padding-left: rem(25px);
  position: relative;

  &:before {
    content: ' ';
    border-radius: 50%;
    background-color: $green-palette-100;
    width: rem(16px);
    height: rem(16px);
    position: absolute;
    left: 0;
  }
}

.rc-table-row:nth-child(2) .balls-palette:before {
  background-color: $green-palette-200;
}

.rc-table-row:nth-child(3) .balls-palette:before {
  background-color: $green-palette-300;
}

.rc-table-row:nth-child(4) .balls-palette:before {
  background-color: $green-palette-400;
}

.rc-table-row:nth-child(5) .balls-palette:before {
  background-color: $green-palette-500;
}

.rc-table-row:nth-child(6) .balls-palette:before {
  background-color: $green-palette-600;
}

.rc-table-row:nth-child(7) .balls-palette:before {
  background-color: $green-palette-700;
}

.rc-table-row:nth-child(8) .balls-palette:before {
  background-color: $green-palette-800;
}

.rc-table-row:nth-child(n + 9) .balls-palette:before {
  background-color: $green-palette-900;
}

.sales-funnel-table {
  height: 100%;

  &__table.ebs-table {
    .rc-table-content {
      padding-bottom: 0;
      border-radius: rem(4px);
    }

    table {
      .rc-table-thead tr th.rc-table-cell,
      .rc-table-tbody tr.rc-table-row td.rc-table-cell {
        padding-top: rem(15px);
        padding-bottom: rem(15px);
      }

      .rc-table-thead tr th.rc-table-cell,
      .rc-table-tbody tr.rc-table-row td.rc-table-cell {
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        box-sizing: border-box;
      }

      .rc-table-tbody tr.rc-table-row:last-child td.rc-table-cell {
        border-bottom: none;
      }

      .rc-table-tbody tr.rc-table-row:last-child td.rc-table-cell {
        border-bottom: none;
      }

      .rc-table-thead tr th.rc-table-cell:last-child,
      .rc-table-tbody tr.rc-table-row td.rc-table-cell:last-child {
        border-right: none;
      }
    }
  }
}

.sales-funnel-card {
  &__no-data {
    @include flex-center;
    height: 100%;
    font-size: rem(20px);
    color: $primary-color;

    .ebs-icon {
      margin-right: rem(10px);
    }
  }
}

.sales-funnel-filter > .ebs-space__item {
  padding-bottom: 0 !important;
}
