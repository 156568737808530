.inline-edit {
  position: relative;
  width: 100%;
  margin: rem(-10px -20px);
  padding: rem(10px 20px);
  transition-property: background-color;
  transition-duration: $transition-time;

  &,
  .ebs-icon {
    transition-property: opacity, color;
    transition-duration: $transition-time;
  }

  .ebs-icon:first-child {
    position: absolute;
    right: rem(20px / 2);
    top: rem(10px / 2);
    opacity: 0;
    color: $grey-400;
    font-size: rem(14px);
  }

  .ebs-input {
    &__container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &__container,
    &__wrapper {
      height: 100%;
    }
  }

  &--active,
  &:hover {
    background-color: $grey-200;
    color: $grey-400;

    .ebs-icon {
      opacity: 1;
    }
  }

  &--active {
    padding: rem(22px 20px);

    .ebs-icon {
      color: $primary-color;
    }
  }
}

.ebs-table .inline-edit {
  .ebs-input__wrapper {
    border-radius: 0;
  }
}
