.drop-zone {
  @include flex-center-column();
  margin-bottom: rem(20px);
  padding: rem(30px);
  border: 2px dashed $dropzone-border-color;
  background-color: $dropzone-background;
  border-radius: rem(4px);
  position: relative;

  &__upload-icon {
    color: $primary-color;
    margin-bottom: rem(10px);
  }

  &__drag-drop {
    margin-bottom: rem(10px);
  }

  &__or {
    color: $primary-color;
    margin-bottom: rem(10px);
  }

  &__browse-file {
    width: rem(150px);
    display: block;

    &__icon {
      width: rem(20px);
      height: rem(20px);
    }
  }
}
