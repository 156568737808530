.application-tooltip {
  width: rem(640px);

  &__list {
    display: block;
    overflow: hidden;

    &__item {
      @include flex-center-column;
      cursor: pointer;
      width: rem(160px);
      height: rem(130px);
      float: left;
      padding: rem(15px);
      text-align: center;
      font-size: rem(12px);

      &__icon {
        @include flex-center;
        border-radius: 50%;
        background-color: $warning-700;
        width: rem(62px);
        height: rem(62px);

        svg {
          overflow: visible;
          width: rem(30px);
          height: rem(30px);
          color: $white;
        }
      }

      span {
        @include fontMedium;
      }

      &:hover {
        background-color: $warning-700;
        border-radius: rem(4px);
        color: $white;

        .application-tooltip__list__item__icon {
          background-color: $white;

          svg {
            color: $warning-700;
          }
        }
      }
    }
  }

  &__footer {
    position: relative;
    text-align: center;
    font-size: rem(12px);
    margin-top: rem($gutter-size);

    svg {
      width: rem(16px);
      height: rem(12px);
      position: absolute;
      top: rem(3px);
      margin-left: rem(5px);
    }
  }

  //this is specific platform colors for application
  $application-default: #0052cc;
  $application-cms: #00749c;
  $application-notifications: #fab63b;
  $application-chat: #3dbaf1;
  $application-tasks: #b7d258;
  $application-storage: #455aea;
  $application-documentation: #f1396f;
  $application-management: #9dc0da;
  $application-calendar: #27acc5;
  $application-faas: #59ba7c;
  $application-server: #a4a2f7;
  $application-monitoring: #b7d258;
  $application-analytics: #5dd9a4;
  $application-bpm: #f44646;

  .application {
    &__item {
      &--default {
        &:hover {
          background-color: $application-default;

          svg {
            color: $application-default;
          }
        }

        > div {
          background-color: $application-default;
        }
      }

      &--cms {
        &:hover {
          background-color: $application-cms;

          svg {
            color: $application-cms;
          }
        }

        > div {
          background-color: $application-cms;
        }
      }

      &--notifications {
        &:hover {
          background-color: $application-notifications;

          svg {
            color: $application-notifications;
          }
        }

        > div {
          background-color: $application-notifications;
        }
      }

      &--chat {
        &:hover {
          background-color: $application-chat;

          svg {
            color: $application-chat;
          }
        }

        > div {
          background-color: $application-chat;
        }
      }

      &--tasks {
        &:hover {
          background-color: $application-tasks;

          svg {
            color: $application-tasks;
          }
        }

        > div {
          background-color: $application-tasks;
        }
      }

      &--storage {
        &:hover {
          background-color: $application-storage;

          svg {
            color: $application-storage;
          }
        }

        > div {
          background-color: $application-storage;
        }
      }

      &--documentation {
        &:hover {
          background-color: $application-documentation;

          svg {
            color: $application-documentation;
          }
        }

        > div {
          background-color: $application-documentation;
        }
      }

      &--management {
        &:hover {
          background-color: $application-management;

          svg {
            color: $application-management;
          }
        }

        > div {
          background-color: $application-management;
        }
      }

      &--calendar {
        &:hover {
          background-color: $application-calendar;

          svg {
            color: $application-calendar;
          }
        }

        > div {
          background-color: $application-calendar;
        }
      }

      &--faas {
        &:hover {
          background-color: $application-faas;

          svg {
            color: $application-faas;
          }
        }

        > div {
          background-color: $application-faas;
        }
      }

      &--server {
        &:hover {
          background-color: $application-server;

          svg {
            color: $application-server;
          }
        }

        > div {
          background-color: $application-server;
        }
      }

      &--monitoring {
        &:hover {
          background-color: $application-monitoring;

          svg {
            color: $application-monitoring;
          }
        }

        > div {
          background-color: $application-monitoring;
        }
      }

      &--analytics {
        &:hover {
          background-color: $application-analytics;

          svg {
            color: $application-analytics;
          }
        }

        > div {
          background-color: $application-analytics;
        }
      }

      &--bpm {
        &:hover {
          background-color: $application-bpm;

          svg {
            color: $application-bpm;
          }
        }

        > div {
          background-color: $application-bpm;
        }
      }
    }
  }
}
