.lead-details {
  display: flex;

  &__left-col {
    width: rem(400px);
    padding: rem(5px);
    margin-bottom: rem(70px);
    flex: auto;
  }

  &__right-col {
    padding: rem(5px);
    width: 100%;
    flex: auto;
  }

  &__general-data {
    position: relative;

    &__item {
      margin-bottom: rem(10px);
      margin-right: rem(10px);

      svg {
        position: relative;
        margin-right: rem(4px);
        color: $ahref-color;
      }
    }
  }

  &__head {
    margin-bottom: rem(10px);
  }

  &__name {
    @include fontBold;
    @include flex-center;
    font-size: rem(16px);
    height: rem(34px);
  }

  &__tooltip {
    .ebs-select-dropdown__container {
      display: none;
    }

    &-label.ebs-label {
      @include flex-center;
      height: rem(22px);
      cursor: pointer;

      .ebs-icon {
        margin-left: rem($gutter-size / 2);
        color: $white;
      }

      &.disabled .ebs-icon {
        color: unset;
      }
    }

    .ebs-select__options > .ebs-select__options-items {
      height: auto;
    }
    &-label.ebs-label.disabled {
      cursor: pointer;
    }
  }

  &__edit-lead {
    top: rem(10px);
    right: rem(10px);

    &.ebs-button--ghost.ebs-button__wrapper {
      position: absolute;
    }

    svg {
      width: rem(10px);
      height: rem(10px);
    }
  }
}
