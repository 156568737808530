.custom-period {
  .ebs-modal {
    border: none;
    background-color: #fff;
  }

  .react-datepicker {
    border-color: transparent;

    .react-datepicker__header {
      background-color: transparent;
    }

    .react-datepicker__day {
      margin: rem(7px);
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-range {
      background-color: $green;
    }

    .react-datepicker__day--in-selecting-range {
      background-color: rgba($green, 0.5);
    }
  }
}
