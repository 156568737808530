.document-item {
  border-bottom: 1px solid $border-color;
  margin: rem(0 -15px);
  padding: rem(10px 15px);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__description {
    max-width: rem(150px);
  }

  &__name {
    font-size: rem(11px);
    overflow-wrap: break-word;
    color: $text-color-light;
  }

  &__file {
    width: 100%;
    display: block;
    font-size: rem(13px);
    color: $grey-500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__button-group {
    &__button {
      position: absolute;
      bottom: 0;
      right: 0;
      width: rem(25px);
      height: rem(25px);
      cursor: pointer;

      .ebs-button__prefix {
        top: rem(12px);
        left: rem(5px);
      }

      button.ebs-button {
        padding: 0;
      }
    }
  }

  .ebs-button__group {
    flex-wrap: nowrap;
  }
}

.document-item.document-opportunity {
  border-bottom: none;
}

.document-form {
  &__radio-button {
    margin-top: rem(7px);

    .ebs-radio__wrapper {
      margin-right: rem(12px);
      float: left;
    }
  }
}
