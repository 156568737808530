* {
  -webkit-text-size-adjust: none;
}

html,
body {
  font-size: 13px;

  @include mq($until: 1720px) {
    font-size: 12px;
  }

  @include mq($until: 1520px) {
    font-size: 11px;
  }
}
