.contacts-table {
  .sort-arrow {
    margin: rem(0 0 0 3px);
  }

  .contacts-table-row td.rc-table-cell {
    background-color: $primary-100 !important;
  }

  td,
  th {
    &:last-child {
      .ebs-action__wrapper {
        margin: 0 auto;
      }
    }
  }

  .pseudo-link {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

.contacts-table.ebs-table td a {
  color: $ahref-color;
}

.table-actions {
  .actions-title {
    color: #616161;
    font-size: rem(11px);
    padding: rem(10px 13px 5px 13px);
    text-transform: uppercase;
  }

  .ebs-action__tooltip-item {
    font-size: rem(11px);
  }
}

.contacts-users-select {
  .ebs-select-dropdown__wrapper {
    display: block;
  }

  .ebs-select__options {
    position: relative;
    box-shadow: none;
  }
}

.ebs-select-value {
  padding-right: rem(25px);
}
