.customers-table {
  .sort-arrow {
    margin: rem(0 0 0 3px);
  }

  td,
  th {
    &:last-child {
      .ebs-action__wrapper {
        margin: 0 auto;
      }
    }
  }

  td {
    cursor: pointer;
  }

  .pseudo-link {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
