.contact-import-steps {
  .contact-import-steps_header {
    margin: rem(15px 0);
  }

  .contact-import-steps_navigation {
    margin-top: rem(-15px);
    margin-bottom: rem(30px);

    &-link {
      margin-top: rem(15px);
    }
  }
}
