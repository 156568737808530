.contract-item {
  position: relative;
  border-bottom: 1px solid $border-color;
  margin: rem(0 -15px);
  padding: rem(10px 15px);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__number {
    @include fontMedium();
    font-size: rem(12px);
    color: #333333;
  }

  &__body {
    background-color: #f8f8f8;
    width: 100%;
    display: block;
    overflow: hidden;

    .ebs-space__item {
      border-right: 1px solid $border-color;

      &:last-child {
        border-right: none;
      }
    }

    &__col {
      display: block;
      float: left;
      padding: rem(5px);

      .contract-value {
        @include fontSemiBold();
        font-size: rem(12px);
        color: #000000;
      }

      .contract-currency {
        font-size: rem(10px);
        color: #444444;
      }

      .contract-balance_overdue {
        font-size: rem(11px);
        color: #a9152c;
      }

      .contract-outstanding-text {
        font-size: rem(10px);
        color: #8f8f8f;
      }

      .contract-loan {
        font-size: rem(11px);
        color: #0e7f3c;
      }

      .contract-loan-text {
        font-size: rem(10px);
        color: #444444;
      }

      span {
        display: block;
        padding-left: rem(5px);
        padding-right: rem(5px);
        min-height: rem(10px);
      }
    }
  }
}
