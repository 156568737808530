.contact-create {
  .contact-create_form {
    max-width: rem(1000px);
  }

  .contact-type-radio {
    .ebs-radio__group {
      padding: rem(10px);
      display: flex;
      border: rem(1px solid $border-color);
      border-radius: rem(3px);
      margin-top: rem(3px);

      .ebs-radio__wrapper {
        width: 50%;
        position: relative;
        top: rem(3px);
      }
    }
  }

  .col-slider {
    position: relative;

    .ebs-form__item {
      position: absolute;
      bottom: 0;
      left: rem(15px);
      right: rem(15px);
    }
  }

  .duplicate-radio {
    margin-top: rem(15px);
  }

  .rc-slider-track {
    background-color: $success-color;
  }

  .rc-slider-handle {
    border-color: $success-color;
  }

  .rc-slider-tooltip {
    &.rc-slider-tooltip-hidden {
      display: block !important;
    }

    .rc-slider-tooltip-inner {
      color: $success-color;
      background-color: transparent;
      box-shadow: none;
      line-height: rem(30px);
      font-weight: 600;

      &:after {
        content: '%';
      }
    }

    .rc-slider-tooltip-arrow {
      display: none !important;
    }
  }
}
