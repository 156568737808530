.user-profile-tooltip {
  &__header {
    font-size: rem(12px);
    color: $grey-400;
    padding: rem(10px 15px);
  }

  &__account-list {
    max-height: rem(300px);
    overflow-y: auto;

    &__item {
      @include flex-center;
      font-size: rem(14px);
      border-bottom: rem(1px) solid $border-color;
      padding: rem(10px 15px);

      &--active {
        background: $grey-100;
        color: $grey-500;
        border-left: 2px solid $primary-color;
        cursor: not-allowed;
      }

      img {
        width: rem(24px);
        margin-right: rem(15px);
      }
    }
  }

  &__profile-management {
    &__item {
      @include flex-center-vert;
      font-size: rem(12px);
      padding: rem(10px 15px);

      svg {
        font-size: rem(16px);
        margin-right: rem(15px);
        width: rem(18px);
      }
    }
  }
}
