.opportunity-item {
  position: relative;
  border-bottom: 1px solid $border-color;
  margin: rem(0 -15px);
  padding: rem(10px 15px);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__value {
    color: $black;
    @include fontSemiBold;
    font-size: rem(13px);
  }

  &__value-currency {
    color: $grey-500;
  }

  &__details {
    width: rem(200px);

    &__slider {
      margin-top: rem(5px);
      border: 1px solid $border-color;
      border-radius: 4px;
      padding: rem(1px 10px);

      .rc-slider-disabled {
        background-color: transparent;
      }

      .rc-slider-handle {
        border-color: $ahref-color;
      }
    }
  }

  &__lead-name {
    font-size: rem(13px);
    color: $text-color-secondary;
  }

  &__close-date {
    font-size: rem(11px);
    color: #8f8f8f;
  }

  &__description {
    color: #747474;
    font-size: rem(11px);
    margin-top: rem(10px);
  }

  &__contact-label {
    color: #8f8f8f;
    font-size: rem(11px);
    display: block;
  }

  &__contact {
    font-size: rem(13px);
    max-width: rem(130px);
    display: block;
  }

  &__footer {
    position: relative;
    min-height: rem(40px);
  }

  &__footer-box {
    position: relative;
    top: rem(10px);
  }

  &__button-group {
    .ebs-button__wrapper {
      width: rem(30px);
      height: rem(25px);
    }

    .ebs-button__prefix {
      left: rem(7px);
    }

    svg {
      width: rem(12px);
      height: rem(12px);
    }
  }

  &__collapse-button.ebs-button__wrapper {
    @include flex-center;
    width: rem(30px);
    height: rem(25px);
    cursor: pointer;

    &.collapsed {
      svg {
        transform: rotate(180deg);
      }
    }

    .ebs-button__prefix {
      @include flex-center;
      position: unset;
      top: unset;
      left: unset;
      transform: unset;

      svg {
        height: rem(6px);
        width: rem(12px);
      }
    }

    button.ebs-button {
      display: none;
    }
  }
}

.opportunity__radio-button {
  @include flex;
  justify-content: space-evenly;
}
