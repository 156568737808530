.activity-log-item {
  position: relative;

  &__body {
    background-color: #fff;
    margin-bottom: rem(10px);
    border-radius: 4px;
    border: 1px solid $border-color;
    padding: rem(10px);
    margin-left: rem(70px);
  }

  &__content {
    @include fontRegular;
    max-width: rem(600px);
    font-size: rem(12px);
    color: $text-color-light;
  }

  &__icon-box {
    position: absolute;
    top: 0;
    left: rem(10px);
    width: rem(30px);
    height: 100%;
  }

  &__icon {
    width: rem(30px);
    height: rem(30px);
    border-radius: 50%;
    background-color: $primary-color;

    &:before {
      content: ' ';
      position: absolute;
      left: rem(15px);
      top: rem(30px);
      height: 100%;
      border-left: 1px solid $border-color;
    }

    &:after {
      content: ' ';
      position: absolute;
      left: rem(30px);
      top: rem(15px);
      width: 100%;
      border-top: 1px solid $border-color;
    }

    svg {
      color: #fff;
      position: absolute;
      width: rem(14px);
      height: rem(14px);
      top: rem(8px);
      left: rem(8px);
    }

    &.task {
      background-color: #019ae6;
    }

    &.opportunity_change {
      background-color: #a9152c;
    }

    &.lead_change {
      background-color: #5b289a;
    }

    &.call {
      background-color: #43b9c5;
    }

    &.email {
      background-color: #18244c;
    }

    &.meeting {
      background-color: #40a43f;
    }

    &.note {
      background-color: #677288;
    }
  }

  &:last-child .activity-log-item__icon:before {
    display: none;
  }

  &__right-side {
    white-space: nowrap;

    .redirect-mail-message {
      cursor: pointer;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 1px #40a43f solid;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .show-more-button {
      cursor: pointer;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 1px #40a43f solid;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__created-at {
    color: #8f8f8f;
  }

  &__delete {
    width: rem(30px);
    min-height: rem(27px);
    position: relative;
  }

  .activity-log-item__delete .activity-log-item__delete__icon.ebs-icon {
    color: $grey-500;
    position: absolute;
    left: rem(-4px);
  }
}

.chat-style {
  white-space: pre-wrap;
}
