.task-item {
  position: relative;
  border-bottom: 1px solid $border-color;
  margin: rem(0 -15px);
  padding: rem(10px 15px);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .action-task {
    width: rem(14px);
    height: rem(14px);
  }

  &__deadline {
    color: #8f8f8f;
    font-size: rem(11px);
    margin-top: rem(10px);
  }

  &__description {
    color: #747474;
    font-size: rem(11px);
    margin-top: rem(10px);
  }

  &__complete-button.ebs-button__wrapper {
    .ebs-button {
      padding-left: rem(30px);
    }

    svg {
      width: rem(13px);
      height: rem(10px);
    }
  }

  &__complete-button.ebs-button__wrapper.task-item__rotate-back {
    display: flex;
    .ebs-button__prefix {
      left: rem(6px);

      svg {
        height: rem(20px);
        width: rem(20px);
      }
    }
  }

  &__collapse-button.ebs-button__wrapper {
    @include flex-center;
    position: absolute;
    bottom: rem(10px);
    right: rem(14px);
    width: rem(30px);
    height: rem(25px);
    cursor: pointer;

    &.collapsed {
      svg {
        transform: rotate(180deg);
      }
    }

    .ebs-button__prefix {
      @include flex-center;
      position: unset;
      top: unset;
      left: unset;
      transform: unset;

      svg {
        height: rem(6px);
        width: rem(12px);
      }
    }

    button.ebs-button {
      display: none;
    }
  }
}
