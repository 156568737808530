.nav-tab__box {
  display: flex;
  margin: 0 0 20px 0;
  border-bottom: 1px solid $border-color;
}
.nav-tab__link {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-size: $gutter-size;
  font-weight: $btn-font-weight;
}
.nav-tab__link--active {
  border-bottom: 2px solid $primary-color;
}
