.contact-import-steps_navigation-link {
  display: inline-block;
  padding: rem(6px 19px 6px 6px);
  border: rem(1px solid $border-color);
  color: $grey-500;
  font-size: rem(14px);
  border-radius: rem(18px);
  position: relative;
  margin-right: rem(20px);

  &::after {
    content: '';
    width: rem(20px);
    height: rem(1px);
    display: block;
    position: absolute;
    top: 50%;
    right: rem(-20px);
    background-color: $border-color;
  }

  &:last-child {
    margin-right: 0;

    &::after {
      display: none;
    }
  }

  &.active {
    font-weight: 600;
    color: #000;

    .contact-import-steps_step {
      background-color: #146f2e;
      color: #fff;
    }
  }

  &.active {
    font-weight: 600;
    color: #000;

    .contact-import-steps_step {
      background-color: $green;
      color: #fff;
    }
  }

  &.passed {
    font-weight: 600;
    color: #fff;
    background-color: $green;

    .contact-import-steps_step {
      background-color: #fff;
      color: #fff;

      svg {
        visibility: visible;
      }
    }
  }

  .contact-import-steps_step {
    height: rem(24px);
    width: rem(24px);
    background-color: #eef2f5;
    font-size: rem(13px);
    border-radius: 100%;
    margin-right: rem(13px);
    display: inline-block;
    text-align: center;
    line-height: rem(24px);
    position: relative;

    svg {
      position: absolute;
      top: rem(5px);
      left: rem(4px);
      visibility: hidden;
    }
  }
}
