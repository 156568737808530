.settings__box {
  width: rem(600px);
  &-text {
    display: block;
    width: rem(450px);
  }
  &-span {
    margin-left: rem(5px);
    color: $primary-500;
  }
}
