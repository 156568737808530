.location-tooltip {
  max-width: rem(150px);
  font-size: rem(10px);

  .row {
    margin-bottom: rem(4px);

    &:last-child {
      margin-bottom: 0;
    }

    &.col:first-child {
      color: $primary-color;
    }
  }
}
