.import-file-upload {
  .file-uploader {
    max-width: rem(800px);
    background-color: #fff;
    border: rem(1px solid $border-color);
    padding: rem(20px);
    border-radius: rem(4px);
  }
  //
  //.ebs-radio__group {
  //  .ebs-radio__wrapper {
  //    margin: rem(15px 0) !important;
  //  }
  //}
}
