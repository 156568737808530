.calls {
  &__type-label.ebs-label {
    @include fontSemiBold();
  }

  &__answered-true {
    color: $primary-color;
  }

  &__answered-false {
    color: $danger-color;
  }

  &__play-button {
    width: rem(30px);
    min-height: rem(27px);
    position: relative;
    cursor: pointer;

    &.ebs-button__wrapper svg.ebs-icon {
      color: $grey-500;
      position: absolute;
      left: rem(-2px);
    }

    button.ebs-button {
      padding: 0;
    }
  }
}
