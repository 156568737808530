.global-search {
  position: relative;
  width: 100%;

  &__input {
    @include flex;
  }

  &__results {
    @include elevation-1;
    position: absolute;
    width: 100%;
    background: $white;
    z-index: 3;
    margin-top: rem(9px);

    .ebs-list-group__item:not(:last-child) {
      border-bottom: 1px solid rgba($border-color, 0.5);
    }

    .ebs-list-group__item {
      color: $grey-500;
    }

    a:hover {
      .ebs-list-group__item {
        background-color: $grey-100;
        color: $primary-color;
      }
    }

    &-container {
      position: relative;
      width: 100%;
    }

    &--advanced {
      position: absolute;
      top: rem($gutter-size / 2);
      right: rem($gutter-size / 2);
      z-index: 3;
    }
  }
}
