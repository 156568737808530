.task-card {
  color: $white;
  border-radius: rem($border-radius);
  overflow: hidden;

  h1 {
    font-weight: 400;
    font-size: rem(21px);
  }

  b {
    font-size: rem(12px);
  }

  small {
    font-size: rem(10px);
  }

  &.row {
    margin: 0;
  }

  > div {
    display: inline-grid;
    align-items: center;
    padding: rem(15px);
    overflow: hidden;

    &:first-child {
      height: rem(100px);
      width: rem(100px);
      flex: unset;
      cursor: pointer;
      padding: 0px;
    }
  }

  &-priority {
    width: rem(6px);
    height: rem(20px);
    background-color: $success-color;
    border-top-left-radius: rem(4px);
    border-bottom-left-radius: rem(4px);

    &--2 {
      background-color: $info-color;
    }

    &--1 {
      background-color: $danger-color;
    }
  }

  &--expired {
    background: #a9162c;
  }

  &--future {
    background: #237e3c;
  }

  &--current {
    background: #1f325f;
  }

  &__description {
    display: flex;
    flex-direction: column;

    b {
      margin-bottom: rem(4px);
    }
  }

  .rc-table-content {
    border-radius: 0;
  }

  .ebs-table {
    &,
    .rc-table-container,
    .rc-table-content,
    table {
      height: 100%;
    }

    thead {
      display: none;
    }

    tbody tr.rc-table-row {
      td.rc-table-cell {
        padding: rem(0 15px);
      }

      &:nth-child(odd) td.rc-table-cell {
        background-color: unset;
      }

      &:nth-child(even) td.rc-table-cell {
        background-color: #f4f5f7;
      }
    }

    a {
      color: $grey-500;
    }
  }

  .active-route-link {
    max-height: 100%;
    height: 100%;
    display: inline-grid;
    align-items: center;
    text-align: center;
  }
}
