.reports-table {
  &.ebs-table .rc-table-container .rc-table-content td.rc-table-cell {
    color: #111;
  }

  &.ebs-table {
    table {
      border-collapse: collapse;
    }

    .rc-table-container .rc-table-thead {
      th.rc-table-cell {
        border: 1px solid $border-color;
        border-top: none;
        text-align: right;
        @include fontBold;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    th,
    td {
      padding: rem(10px 20px);
    }

    .rc-table-tbody tr.rc-table-row {
      td.rc-table-cell {
        border: 1px solid $border-color;
      }

      &:last-child td.rc-table-cell {
        border-bottom: none;
      }

      td.rc-table-cell:first-child {
        border-left: none;
      }

      td.rc-table-cell:last-child {
        border-right: none;
      }
    }
  }

  .inline-edit {
    margin: rem(-10px -20px);
    padding: rem(10px 20px);
    width: calc(100% + #{rem(40px)});

    &--active {
      padding-top: rem(19px);
      padding-bottom: rem(19px);
    }
  }
}

.reports-point {
  width: rem(16px);
  height: rem(16px);
  border-radius: 100%;
  background: $border-color;
}

.reports-table-calls.ebs-table {
  .rc-table-tbody tr.rc-table-row:nth-child(3n-1) td.rc-table-cell:first-child,
  .rc-table-tbody tr.rc-table-row:nth-child(3n) td.rc-table-cell:first-child {
    border-left: 1px solid $border-color;
  }

  .rc-table-tbody tr.rc-table-row:nth-last-child(3) td.rc-table-cell[rowspan='3'] {
    border-bottom: none;
  }
}
