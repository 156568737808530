.ebs-avatar .ebs-avatar__short-alt {
  @include fontRegular;
}

.ebs-table.no-border .rc-table-container .rc-table-content {
  border: none;
}

.ebs-table {
  .rc-table-container .rc-table-content .rc-table-tbody tr:last-child td {
    border-bottom: none;
  }

  .rc-table-container .rc-table-content {
    .rc-table-cell {
      @include fontSemiBold;
      font-size: rem(11px);

      a {
        color: $black;
        transition: 0.2s;
      }
    }

    th.rc-table-cell {
      color: #18244c;
      @include fontBold;
    }

    td.rc-table-cell {
      color: $black;
      padding: rem(15px 16px);
    }
  }
}

.medium {
  @include fontMedium();
}

.bold {
  @include fontSemiBold();
}

.small-multi-select {
  .ebs-select-label {
    height: rem(16px);
  }

  .ebs-select {
    padding-top: rem(5px);
    padding-bottom: rem(5px);
  }

  .ebs-select-count {
    height: rem(20px);
    line-height: rem(20px);
    padding-top: 0;
    padding-bottom: 0;
  }
}

.player-wrapper {
  padding: rem(50px 0);
}

.create-request {
  width: 85%;
  margin: rem(20px auto);
}

.full-height {
  height: 100%;
}

.table-cell--clickable td.rc-table-cell {
  cursor: pointer;
}

.bg {
  &-transparent {
    background: transparent !important;
  }

  &-danger {
    background: $danger-color !important;
  }
}

.white {
  color: $white;
}

.no-border {
  border: none !important;
}

.no-wrap {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-background.ebs-card__body {
  background-color: transparent;
}

.textarea-static-width {
  .ebs-textarea {
    resize: vertical;
    min-height: rem(120px);
  }
}

.overflow-visible {
  overflow: visible !important;
}

.disabled-icon {
  color: $grey-400 !important;
}

.flex-space-between {
  @include flex-space-between;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}
