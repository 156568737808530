.period-drop-list {
  &__separator:before {
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: $border-color;
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
  }

  &__custom {
    background-color: $card-body-bg-color;
  }
}
