// Main Colors
$primary-color: #106928;
$success-color: #1b7c17;
$info-color: #2d75ee;
$warning-color: #f69808;
$danger-color: #d11d34;
$normal-color: #d4d4d4;
$headers-color: #262626;

$warning-background-color: #fff9e1;

$dropzone-border-color: #057a35;
$dropzone-background: #e6f0ed;

$white: #fff;
$black: #000;
$green: #106928;

$primary-100: #dff8cf;
$primary-200: #baf1a3;
$primary-300: #84d76e;
$primary-400: #52b044;
$primary-500: #1b7c17;
$primary-600: #106a14;
$primary-700: #0b5915;
$primary-800: #074715;
$primary-900: #043b14;

$success-100: #dff8cf;
$success-200: #baf1a3;
$success-300: #84d76e;
$success-400: #52b044;
$success-500: #1b7c17;
$success-600: #106a14;
$success-700: #0b5915;
$success-800: #074715;
$success-900: #043b14;

$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #ececec;
$grey-300: #c6c6c6;
$grey-400: #8e8e8e;
$grey-500: #444444;
$grey-600: #3a3132;
$grey-700: #302224;
$grey-800: #27151a;
$grey-900: #200d13;

$green-palette-900: #043b14;
$green-palette-800: #0b5915;
$green-palette-700: #106a14;
$green-palette-600: #097d40;
$green-palette-500: #399666;
$green-palette-400: #69af8a;
$green-palette-300: #97c7b0;
$green-palette-200: #c7e0d5;
$green-palette-100: #e3efeb;

$red-palette-900: #670439;
$red-palette-800: #7c073b;
$red-palette-700: #9a0b3d;
$red-palette-600: #aa0017;
$red-palette-500: #b91e3c;
$red-palette-400: #ca4f66;
$red-palette-300: #d98595;
$red-palette-200: #f0b7c4;
$red-palette-100: #fdd9cf;
