.ebs-crm {
  .ebs-layout__top-bar {
    .ebs-input__search-wrapper {
      width: 100%;

      .ebs-input__wrapper {
        border: none;
      }
    }
  }

  &__language-select {
    width: rem(62px);

    .ebs-select-value {
      margin: 0;
    }
  }
}
