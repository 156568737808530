$ahref-color: #1f325f;
$ahref-hover-color: #35466f;

// Gutters
$gutter-size: 14px;

// Grid
$grid-xxl-size: 1000px; /*todo need to solve problem with rem*/
$grid-xl-size: 900px;
$grid-lg-size: 800px;
$grid-md-size: 768px;
$grid-sm-size: 576px;

// Components
// Components
$border-color: #d7dae0;

// Typography colors
$heading-color: #282828;
$text-color: $black;
$text-color-secondary: #333;
$text-color-light: #747474;
$table-filter-active-color: $grey-400;

$font-family: 'Montserrat', sans-serif;
$font-size-base: 13px;
$font-weight-base: 500;

// Buttons
$btn-font-weight: 600;
$btn-font-weight-sm: $btn-font-weight;
$btn-font-weight-lg: $btn-font-weight;

$btn-type-ghost-border-color: $border-color;
$btn-type-ghost-background: $white;
$btn-type-ghost-color: $black;

// Details card
$details-card-padding: 20px;
$details-card-header-icon-size: 70px;

// Layout
$layout-top-bar-side-left-magin-left: 0;
$layout-top-bar-border-color: 1px solid $grey-300;
$layout-top-bar-background: $white;

$layout-top-bar-toggler-color: $green;
$layout-top-bar-toggler-border-color: $border-color;

$layout-top-bar-avatar-background: $white;
$layout-top-bar-avatar-alt-color: $white;
$layout-top-bar-avatar-color: $text-color;
$layout-top-bar-avatar-icon-btn-color: $white;

// Label
$label-height: 18px;
$label-padding: 0 5px;

// Sidebar
$sidebar-background: $white;
$sidebar-bottom-background: $white;
$sidebar-item-text-color: $green;
$sidebar-item-hover-background: #106928;
$sidebar-item-hover-color: $white;

// Table
$table-border: 1px solid $border-color;
$table-body-cell-border: transparent;
$table-body-mobile-cell-border: $table-border;
$table-head-font-weight: bold;
$table-body-font-size: 12px;
$table-head-font-size: 11px;
$table-head-background: $white;
$table-head-cell-border: transparent;
$table-sub-cell-border: $table-border;

// Collapse
$collapse-group-space-size: $details-card-padding;
$collapse-header-title-font-weight: 600;
$collapse-header-border-bottom: 2px solid;

// Form
$field-label-font-weight: 500;

// Tabs
$tab-border-bottom: 1px solid $border-color;
$tab-item-border-bottom-active: none;

$card-body-bg-color: #f5f9fb;
$card-border-color: $border-color;
$background-content: #f5f9fb;
$table-body-cell-hover-background: #f5f9fb;
$collapse-body-bg-color: #f5f9fb;
$card-box-shadow: none;
