.activity-filter {
  .ebs-select__wrapper {
    .activity-filter__select-icon.ebs-icon {
      color: $ahref-color;
    }
  }

  &__button-group {
    .ebs-button__prefix {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .ebs-button__wrapper {
      @include flex-center;
    }
  }

  &__button-icon {
    width: rem(35px);
  }
}
