.kanban {
  padding: rem(12px);
  display: block;
}

.kanban-columns {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: rem(12px);
  height: calc(100vh - 230px);
}

.kanban-column {
  width: 100%;
  max-width: rem(370px);
  min-width: rem(300px);
  background-color: $card-body-bg-color;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  margin-right: rem(10px);
  flex: 1;
  overflow: hidden;

  &__header {
    padding: rem(10px);
  }

  &__value {
    width: 100%;
    height: rem(35px);
    background-color: $white;
    padding: rem(10px);
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    &__label {
      color: $text-color-light;
    }
  }

  &__body {
    padding: rem(10px 10px 80px);
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
  }

  &__title {
    @include fontMedium;
    text-transform: uppercase;
  }

  &__count {
    padding: rem(2px 0);
    width: rem(30px);
    color: $white;
    text-align: center;
    font-size: rem(10px);
    border-radius: $border-radius;
    background-color: green;
  }
}

.kanban-item {
  background-color: $white;
  margin-bottom: rem(10px);
  border: 1px solid $border-color;
  border-radius: $border-radius;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    @include fontMedium;
    font-size: rem(13px);
    padding: rem(15px 25px);
    height: rem(50px);
    color: $ahref-color;
    border-bottom: 1px solid $border-color;
  }

  &__body {
    padding: rem(15px 25px);
    overflow: hidden;
  }

  &__avatar.ebs-avatar {
    float: left;
    margin-right: rem(10px);
    min-width: rem(40px);
    min-height: rem(40px);
  }

  &__avatar-description {
    float: left;
    display: block;

    &__item {
      line-height: rem(16px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;

      &--phone {
        @include fontRegular;
        font-size: rem(12px);
        color: $text-color-light;
      }

      &--value {
        font-size: rem(13px);
        @include fontMedium;
      }
    }
  }
}
