.task-table {
  &__description {
    width: rem(100px);

    &__tooltip {
      display: block;
      width: rem(250px);
    }
  }

  &__deadline-label.ebs-label {
    @include fontSemiBold();
  }

  &__priority-label {
    width: rem(70px);
  }

  &__confirm.ebs-button__wrapper {
    border-radius: 50%;
    width: rem(25px);
    height: rem(25px);
    position: relative;

    .ebs-button__prefix {
      position: absolute;
      top: rem(12px);
      left: rem(6px);
    }

    .ebs-loader__spinner {
      margin-top: rem(-1px);
      margin-left: rem(-3px);
    }

    &.active .ebs-loader__spinner {
      border-color: $grey-300;
      border-top-color: $white;
    }
  }
  &__confirm.completed {
    text-decoration: line-through;
    color: $grey-400;

    .ebs-label--info,
    .ebs-label--danger {
      color: $grey-400;
    }
    .ebs-label--fill.ebs-label--success:not(.ebs-label--icon):not(.disabled),
    .ebs-label--fill.ebs-label--info:not(.ebs-label--icon):not(.disabled),
    .ebs-label--fill.ebs-label--danger:not(.ebs-label--icon):not(.disabled) {
      color: #fff;
      background-color: $grey-300;
    }
  }
}
