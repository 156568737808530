.multiple-check-box-select {
  position: relative;

  &__select {
    border-radius: rem(4px);
    border: 1px solid $border-color;
    line-height: rem(30px);
    height: rem(32px);
    display: flex;
    padding: rem(0 10px);
    cursor: pointer;
    white-space: nowrap;

    .ebs-button {
      color: #18244c !important;
      font-weight: 500 !important;
      font-family: $font-family;
    }
  }

  svg.multiple-check-box-select__select-icon.ebs-icon {
    color: #18244c;
  }

  &__select-options {
    z-index: 9;
    display: none;
    background-color: $white;
    border-radius: rem(4px);
    border: 1px solid $grey-300;
    position: absolute;
    right: 0;
    margin-top: rem(5px);

    &__body {
      display: block;
      overflow: hidden;
    }

    &__label.ebs-form__item {
      padding: rem(6px);
      border-bottom: 1px solid $border-color;
      margin-bottom: 0;
      width: 100%;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background-color: $card-body-bg-color;
      }

      &:last-child {
        border: none;
      }
    }

    &__label.ebs-form__item:not(:last-child) {
      margin-bottom: 0;
    }

    &__label .ebs-checkbox__wrapper.has-text .ebs-checkbox {
      top: 1px;
    }
  }

  &.active &__select-options {
    display: block;
  }

  &.active &__select,
  &__select:hover {
    border-color: $primary-color;
    background-color: $primary-color;
    color: $white;

    svg {
      color: $white !important;
    }
  }

  &.active .ebs-button,
  &:hover .ebs-button {
    color: $white !important;
  }

  .ebs-button__prefix:after {
    content: ' ';
    display: block;
    width: 1px;
    height: rem(32px);
    background-color: $border-color;
    position: absolute;
    right: rem(-12px);
  }
}
