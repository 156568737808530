.audio-player-container {
  position: fixed;
  z-index: 998;
  bottom: 0;
  right: 0;
  left: rem($sidebar-width);
  width: auto;
  background-color: $background-content;
  padding: rem(20px);
  border-top: 1px solid #dfe1e6;
  transition: left 0.2s ease;
  box-shadow: 0px -4px 6px 0px $grey-200;
  display: flex;

  .audio-player-close {
    margin-left: 20px;
    cursor: pointer;
    transition: var(--transition);

    &:hover {
      color: $grey-500;
    }
  }
}
