.mapping-fields {
  max-width: rem(1000px);

  .mapping-fields_head {
    //font-size: rem(14px);
    //font-weight: 600;
  }

  .mapping-fields_names {
    font-size: rem(12px);
    font-weight: 600;
    color: $green;
    padding: rem(6px 0);

    .col:last-child,
    .col:first-child {
      line-height: rem(34px);
    }

    .ebs-select__input-wrapper {
      max-width: rem(300px);
    }
  }
}

.confirm-import {
  &__last-col {
    padding-left: rem(150px);
  }
}
