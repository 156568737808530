.reports-layout {
  &__navigation__link {
    .ebs-button__wrapper {
      position: relative;
    }

    .ebs-button__prefix {
      display: block;
      position: absolute;
      top: rem(16px);
      left: 0;
      width: rem(33px);
      height: rem(33px);
      border-right: 1px solid $border-color;

      svg.ebs-icon {
        color: $heading-color;
        margin: rem(9px auto);
        height: rem(17px);
      }
    }
  }

  &__navigation__link-group {
    &.ebs-button__group a:first-child .reports-layout__navigation__link.ebs-button__wrapper {
      border-top-left-radius: rem(4px);
      border-bottom-left-radius: rem(4px);
    }

    &.ebs-button__group a:last-child .reports-layout__navigation__link.ebs-button__wrapper {
      border-top-right-radius: rem(4px);
      border-bottom-right-radius: rem(4px);
      border-left: none;
    }
  }
}
